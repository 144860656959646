import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';


const AppContext = createContext("");

const AppProvider = ({ children }) => {
	const [selectedWebinars, setSelectedWebinars] =  useState(() => []);

	return <AppContext.Provider 
			value={{
				selectedWebinars,
				setSelectedWebinars
			}}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.node,
};


export { AppProvider, AppContext };
