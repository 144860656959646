import React from 'react';
import { AppProvider } from './src/context';
import '@styles/main.scss';

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;

const onInitialClientRender = () => {
    const winLocation = window.location.search;
    const isEDMode = window.location.href.indexOf('ed=1') > -1;
    const isiBar = document.querySelector('.floatingIsi');

    if (isEDMode) {

        disableAnimations();

        if (isiBar)
            isiBar.style.display = "none";

        //tabs selection
        if (winLocation.substring(1).split('&').indexOf('section=ih-video-tab') > -1 || winLocation.substring(1).split('&').indexOf('section=narcolepsy-video-tab') > -1 || winLocation.substring(1).split('&').indexOf('section=narcolepsy-unbranded-video-tab') > -1 || winLocation.substring(1).split('&').indexOf('section=ih-unbranded-video-tab') > -1) {
            document.querySelector('.tabButtons button:last-child').click();
        }


        if (winLocation.substring(1).split('&').indexOf('section=narcolepsy') > -1) {
            document.querySelectorAll('.getStartedCta button')[0].click();
        }

        if (winLocation.substring(1).split('&').indexOf('section=ih') > -1) {
            document.querySelectorAll('.getStartedCta button')[1].click();
        }

        if (winLocation.substring(1).split('&').indexOf('isi=1') > -1) {
            isiBar.style.display = "block";
            isiBar.style.position = 'absolute';
        }

        if (winLocation.substring(1).split('&').indexOf('error=1') > -1 || winLocation.substring(1).split('&').indexOf('error=dob') > -1) {
            document.querySelector('.registerButtonItem').click();
        }


        if (winLocation.substring(1).split('&').indexOf('video=1') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[0].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=2') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[1].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=3') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[2].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=4') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[3].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=5') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[4].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=6') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[5].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('video=7') > -1) {
            document.querySelectorAll('.MuiTab-root')[1].click();
            setTimeout(() => {
                document.querySelectorAll('.videoItem a')[6].click();
                hideIsiBarTimed();
            }, 1000);
        }

        if (winLocation.substring(1).split('&').indexOf('cookie=1') > -1) {
            document.querySelector('.cookieContainer').classList.remove('hide');
        }

        if (window.location.pathname === '/error') {
            document.getElementById('main').style.minHeight = '400px';
        }

        if (winLocation.substring(1).split('&').indexOf('radio=all') > -1) {
            setTimeout(() => {
                const radioGroups = document.querySelectorAll('.MuiFormGroup-root');
                const checkGroups = document.querySelectorAll('.MuiCheckbox-root')

                radioGroups.forEach(group => {
                    const radiobtn = group.querySelector('input')
                    if(radiobtn.getAttribute("type") !== "checkbox")
                    radiobtn.click();
                });

                checkGroups.forEach(chkgroup => {
                    const chkbtn = chkgroup.querySelector('input')
                    chkbtn.click();
                });
            }, 1000);
            
        }

        if(winLocation.substring(1).split('&').indexOf('radio=yesno') > -1) {
            setTimeout(() => {
                document.querySelectorAll("[name=text-reminder-group")[0].click();
                document.querySelectorAll("[name=been-diagnosed-group")[1].click();
                document.querySelectorAll("[type=checkbox]")[0].click();
            }, 1000);
        }

        if(winLocation.substring(1).split('&').indexOf('radio=yesyesno') > -1) {
            setTimeout(() => {
                document.querySelectorAll("[name=text-reminder-group")[0].click();
                document.querySelectorAll("[name=been-diagnosed-group")[0].click();
                document.querySelectorAll("[name=on-treatment-group")[1].click();
                document.querySelectorAll("[name=describes-you-group")[0].click();
                document.querySelectorAll("[type=checkbox]")[0].click();
            }, 1000);
        }
    }

    function hideIsiBarTimed() {
        setTimeout(() => {
            const floatingISI = document.querySelector('.floatingIsi');
            if (floatingISI)
                floatingISI.style.display = "none";
        }, 2000);
    }

    function disableAnimations() {
        var reveals = document.querySelectorAll('.reveal');
        var fadeIn = document.querySelectorAll('.fade-in');
        var fadeInMoveUp = document.querySelectorAll('.fade-in-move-up');

        for (var i = 0; i < reveals.length; i++) {
            reveals[i].classList.remove('active');
            reveals[i].classList.remove('reveal');
        }

        for (i = 0; i < fadeIn.length; i++) {
            fadeIn[i].classList.remove('fade-in');
        }

        for (i = 0; i < fadeInMoveUp.length; i++) {
            fadeInMoveUp[i].classList.remove('fade-in-move-up');
        }


    }
}

export {
    onInitialClientRender
}