exports.components = {
  "component---src-pages-error-jsx": () => import("./../../../src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-idiopathic-hypersomnia-confirmation-jsx": () => import("./../../../src/pages/idiopathic-hypersomnia/confirmation.jsx" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-confirmation-jsx" */),
  "component---src-pages-idiopathic-hypersomnia-index-jsx": () => import("./../../../src/pages/idiopathic-hypersomnia/index.jsx" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-index-jsx" */),
  "component---src-pages-idiopathic-hypersomnia-on-demand-video-jsx": () => import("./../../../src/pages/idiopathic-hypersomnia/on-demand-video.jsx" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-on-demand-video-jsx" */),
  "component---src-pages-idiopathic-hypersomnia-register-jsx": () => import("./../../../src/pages/idiopathic-hypersomnia/register.jsx" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-register-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-narcolepsy-confirmation-jsx": () => import("./../../../src/pages/narcolepsy/confirmation.jsx" /* webpackChunkName: "component---src-pages-narcolepsy-confirmation-jsx" */),
  "component---src-pages-narcolepsy-index-jsx": () => import("./../../../src/pages/narcolepsy/index.jsx" /* webpackChunkName: "component---src-pages-narcolepsy-index-jsx" */),
  "component---src-pages-narcolepsy-on-demand-video-jsx": () => import("./../../../src/pages/narcolepsy/on-demand-video.jsx" /* webpackChunkName: "component---src-pages-narcolepsy-on-demand-video-jsx" */),
  "component---src-pages-narcolepsy-register-jsx": () => import("./../../../src/pages/narcolepsy/register.jsx" /* webpackChunkName: "component---src-pages-narcolepsy-register-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-video-jsx": () => import("./../../../src/pages/video.jsx" /* webpackChunkName: "component---src-pages-video-jsx" */),
  "component---src-pages-xywav-idiopathic-hypersomnia-confirmation-jsx": () => import("./../../../src/pages/xywav-idiopathic-hypersomnia/confirmation.jsx" /* webpackChunkName: "component---src-pages-xywav-idiopathic-hypersomnia-confirmation-jsx" */),
  "component---src-pages-xywav-idiopathic-hypersomnia-index-jsx": () => import("./../../../src/pages/xywav-idiopathic-hypersomnia/index.jsx" /* webpackChunkName: "component---src-pages-xywav-idiopathic-hypersomnia-index-jsx" */),
  "component---src-pages-xywav-idiopathic-hypersomnia-on-demand-video-jsx": () => import("./../../../src/pages/xywav-idiopathic-hypersomnia/on-demand-video.jsx" /* webpackChunkName: "component---src-pages-xywav-idiopathic-hypersomnia-on-demand-video-jsx" */),
  "component---src-pages-xywav-idiopathic-hypersomnia-register-jsx": () => import("./../../../src/pages/xywav-idiopathic-hypersomnia/register.jsx" /* webpackChunkName: "component---src-pages-xywav-idiopathic-hypersomnia-register-jsx" */),
  "component---src-pages-xywav-narcolepsy-confirmation-jsx": () => import("./../../../src/pages/xywav-narcolepsy/confirmation.jsx" /* webpackChunkName: "component---src-pages-xywav-narcolepsy-confirmation-jsx" */),
  "component---src-pages-xywav-narcolepsy-index-jsx": () => import("./../../../src/pages/xywav-narcolepsy/index.jsx" /* webpackChunkName: "component---src-pages-xywav-narcolepsy-index-jsx" */),
  "component---src-pages-xywav-narcolepsy-on-demand-video-jsx": () => import("./../../../src/pages/xywav-narcolepsy/on-demand-video.jsx" /* webpackChunkName: "component---src-pages-xywav-narcolepsy-on-demand-video-jsx" */),
  "component---src-pages-xywav-narcolepsy-register-jsx": () => import("./../../../src/pages/xywav-narcolepsy/register.jsx" /* webpackChunkName: "component---src-pages-xywav-narcolepsy-register-jsx" */)
}

